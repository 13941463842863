class How
  constructor: ->
    $('.how__hide').on 'click', () =>
      @hide()

    $(window).on 'DOMContentLoaded load scroll', (event) =>
      rect = $('#how-section')[0].getBoundingClientRect()
      isVisible = rect.top <= window.innerHeight and rect.bottom >= 0
      @hide() unless isVisible

  show: ->
    window.location.hash = ''
    $(window).scrollTop(0)
    $('.how__hide').fadeIn()
    $('#how-section').slideDown 'fast', () =>
      @showItem 0, () => return
    $(window).trigger 'resize'

  hide: ->
    $('.how__hide').fadeOut()
    $('#how-section').slideUp()
    $(window).trigger 'resize'

  showItem: (index = 1, callback) ->
    item = $('#how-section .how__item').get(index)
    $(item)
    .addClass 'visible'
    .animate {
      opacity: 1
    }, {
      duration: 150
      complete: () =>
        if index <= $('#how-section .how__item').length
          @showItem index + 1
        else
          callback()
    }

window.how = new How()